<template >
   <div id="root" >
        <!-- 打印成功返回 -->
        <div
        v-if="printSuccess"
        width="70%"
        center>
            <div style="text-align:center;">
                <p style="width:100%;font-size:20px;line-height:40px;">所有请求已发送给打印机</p>
                <p style="width:100%;font-size:20px;line-height:40px;">如果打印未开始，请查看右下角打印队列</p>
            </div>            
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;">
                <el-button type="primary" @click="printSuccess = false;exit();">确 定</el-button>
            </div>
        </div>     
        <!-- 配置打印机-角色一普通打印 -->
        <div
        v-if="selectPrinterFlag1"
        width="70%"
        center>
            <div style="text-align:center;">
                <p style="width:100%;font-size:16px;line-height:40px;height:40px;">选择打印机</p>
                <el-select v-model="currentPrinter" placeholder="请选择">
                    <el-option
                    v-for="(item,key) in printerList"
                    :label="item"
                    :value="key"
                    :key="key">
                    </el-option>
                </el-select>
                <p style="width:100%;font-size:16px;line-height:40px;height:40px;">每页打印份数</p>
                <el-input-number v-model="printCopies" :min="1" :max="10000" label="打印份数"></el-input-number>   
            </div>  
                      
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;">
                <el-button style="" type="primary" @click="selectPrinterFlag = false;printInit(-1);">预 览</el-button>
                <el-button style="" type="success" @click="selectPrinterFlag = false;printInit(1);">打 印</el-button>
            </div>
        </div>

        <!-- 安装LODOP -->
        <div
        v-if="installLodop"
        width="70%"
        >
            <div style="text-align:left;">
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;color:red;">检测到您的电脑未安装LODOP打印控件，请安装后再继续进行打印</p>
                <p style="width:80%;font-size:23px;line-height:40px;color:blue;margin:0 auto;">安装步骤：</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">1.打开LODOP <a target="_blank" href="http://www.c-lodop.com/download.html">官网-www.c-lodop.com</a>,根据您的操作系统选择合适的空间版本进行安装</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">2.或直接点击这里下载 →→ <a target="_blank" href="http://www.c-lodop.com/download/Lodop6.226_Clodop4.127.zip">LODOP打印控件</a> ，解压后安装</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;">3.安装完成后启动LODOP空间，启动成功界面如图所示，右下角会有图标常驻：</p>
                <p style="width:80%;font-size:20px;line-height:40px;margin:0 auto;"><img :src="require('../../assets/img/home/lodop1.png')" />-<img :src="require('../../assets/img/home/lodop2.png')" /></p>
                <p style="width:80%;font-size:20px;margin:0 auto;height:80px;line-height:80px;">3.以上步骤执行成功后，点击&nbsp;这里 →→&nbsp;&nbsp; <a href="javascript:location.reload();" style="font-size:30px;">重新打印</a> ←← 即可继续打印 </p>
            </div>            
        </div>  

        

  </div>
</template>
<script>
import methods from './printLodopStatic/methods.js';
import datas from './printLodopStatic/object.js';
import tools from '../../extends/tools';
import { getLodop } from '../../extends/lodop.js';
export default {
    name:'printStatic',
    props:{
        c_status:{
            type:Boolean,
            default:false
        },
        printId:{
            type:Number,
            default:0
        },
        // 模版类型,对应模版类型里的4个id，1发货单，2备货单，3普通快递单
        tplType:{
            type:Number,
            default:1
        },
        //打印动作,simple为角色一打印，order为角色2订单打印
        printAction:{
            type:String,
            default:'simple1'
        }
    },
    data(){
        return datas;
    },
    methods:methods,
    destroyed(){
        // 退出时初始化数据
        this.selectPrinterFlag1 = false;
        this.currentPrinter = '';
        this.currentTemplate = '';
        this.orderList = '';
        this.selectFileData = [];
        localStorage.setItem('selectFileData','');
    },
    created(){
        
    },
    mounted() {
        //角色一连续打印数据
        let sdata = localStorage.getItem('selectFileData');
        if(!!sdata){
            this.selectFileData = JSON.parse(sdata);
        }
        this.selectPrinterFlag1 = true;
        if(!this.LODOP){
            //重新加载一次LODOP
            setTimeout(()=>{
                alert('检测到打印控件异常，正在尝试修复');
            },150);
            let loader = tools.loading(null,this);
            setTimeout(()=>{
                this.LODOP2 = getLodop();
                if(!!this.LODOP2){
                    this.$message({
                        type:'success',
                        message:'修复成功！'
                    })
                    this.LODOP = this.LODOP2;
                    this.init();
                }else{
                    this.$message({
                        type:'success',
                        message:'修复失败，请重启您的LODOP控件后，刷新本页面再试！'
                    })
                }
                tools.loading(loader);
            },3000);
            return;
        }
        this.init();
    }
}

</script>

<style scoped lang="less">
@import url('../../assets/less/normal');
.top{
    height:47px;background:#fff;
}
.order_list{
    height:490px;background:#fff;
}
.footer{
    height:60px;background:#fff;position:relative;line-height:60px;
}
.cols{
    height:30px;
}
.cainiao_row{
    height:57px;line-height:60px;
}
.odd{
    .cols{
        background: #fafafa;
    }
}

</style>