import NZH from 'nzh';

class Printer {
    constructor(host) {
        this.host = host;
    }

    // 连续打印;
    printMutiple(model) {
        //有数据源，excel打印
        //导入Excel打印
        let nzhcn = NZH.cn;
        //初始化
        this.host.selectFileData.forEach((rowdata, index) => {
            let printIndex = 'print' + index;
            this.host.LODOP.PRINT_INIT(printIndex);
            this.host.LODOP.SET_PRINT_PAGESIZE(1, this.host.paper.width * 10, this.host.paper.height * 10, printIndex);
            // 循环
            for (var i = 0; i < this.host.dragbox.length; i++) {
                let d = this.host.dragbox[i];
                let top = d.boxObject.top;
                let left = d.boxObject.left;
                let width = d.boxObject.width;
                let height = d.boxObject.height;
                let deg = d.boxObject.deg;
                let textFontSize = d.textObject.fontSizeNum;
                delete d.boxReverse.transform;
                delete d.boxReverse.writeingMode;

                //需要转换的动态数据
                for (let k in rowdata) {
                    //动态数据
                    let sk = '$$' + k + '$$';
                    // console.log(sk);
                    // 转换文本数据
                    // console.log(d);
                    if (d.type == 'text' && d.source == sk) {
                        // 填充样式
                        let allText = d.text + '';
                        // 替换动态数据
                        if (!!d.source && d.source == sk) {
                            if (d.text) {
                                allText = allText + ':' + rowdata[k];
                            } else {
                                allText = rowdata[k];
                            }
                        }
                        // 转化
                        let textObject = d.textObject;
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp + 20;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }
                        // 处理大写转换
                        if (textObject.textTransform == "uppercase") {
                            allText = nzhcn.toMoney(allText);
                            allText = allText.replace('人民币', '');
                        }
                        let className = 'text' + i;
                        this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
                        // console.log(d.text);
                        // var p = '';
                        // p += "this.host.LODOP.ADD_PRINT_TEXTA('"+className+"',"+top+","+left+","+width+","+height+",'"+d.text+"');";
                        // 旋转变换
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -6);
                        }
                        // 处理居中居左
                        if (d.boxReverse.textAlign == 'center') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',2);";
                        } else if (d.boxReverse.textAlign == 'right') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',3);";
                        }
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
                        // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontName','"+textObject.fontFamily+"');";
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
                        // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontSize',"+textObject.fontSizeNum+");";
                        if (textObject.fontWeigiht == 'bold') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Bold',1);";
                        }
                        if (textObject.fontStyle == 'italic') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Italic',1);";
                        }
                        if (textObject.textDecoration == 'underline') {
                            this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Underline',1);";
                        }
                    }

                    // 转换条形码数据
                    if (d['type'] == 'barCode' && d.source == sk) {
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }

                        this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, rowdata[k]);
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                        }
                    }
                    // 转换二维码
                    if (d['type'] == 'qr' && d.source == sk) {
                        if (deg == 90) {
                            let tmp = width;
                            width = height;
                            height = tmp;
                            top -= (height - width) / 2;
                            left += (height - width) / 2;
                        }
                        this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', rowdata[k]);
                        this.host.LODOP.SET_PRINT_STYLEA(0, "QRCodeVersion", 20);
                        if (deg == 90) {
                            this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                        }
                    }
                }

                this.getStaticHTML(d);
            }
            // 发送请求给lodop
            this.host.LODOP.SET_PRINTER_INDEX(this.host.currentPrinter);
            this.host.LODOP.SET_PRINT_COPIES(this.host.printCopies);
            if (model > 0) {
                this.host.LODOP.PRINT();
            } else {
                this.host.LODOP.PREVIEW();
            }
            // 隐藏背景图片
        });
    }

    getStaticHTML(d) {
        let top = d.boxObject.top;
        let left = d.boxObject.left;
        let width = d.boxObject.width;
        let height = d.boxObject.height;
        let deg = d.boxObject.deg;
        let textFontSize = d.textObject.fontSizeNum;
        //导入Excel打印
        let nzhcn = NZH.cn;
        // 直接打印
        if (d.type == 'text') {
            let textObject = d.textObject;
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp + 20;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            let className = 'text' + Math.random();
            let allText = d.text + '';
            // 处理大写转换
            if (textObject.textTransform == "uppercase") {
                allText = nzhcn.toMoney(allText);
                allText = allText.replace('人民币', '');
            }
            var p = '';
            this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
            // p += "this.host.LODOP.ADD_PRINT_TEXTA('"+className+"',"+top+","+left+","+width+","+height+",'"+d.text+"');";
            // 旋转变换
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -3);
            }
            // 处理居中居左
            if (d.boxReverse.textAlign == 'center') {
                this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',2);";
            } else if (d.boxReverse.textAlign == 'right') {
                this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',3);";
            }

            this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontName','"+textObject.fontFamily+"');";
            this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
            // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontSize',"+textObject.fontSizeNum+");";

            if (textObject.fontWeigiht == 'bold') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Bold',1);";
            }
            if (textObject.fontStyle == 'italic') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Italic',1);";
            }
            if (textObject.textDecoration == 'underline') {
                this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Underline',1);";
            }
            // console.log(p);
        }
        //转换线条
        if (d['type'] == 'lineBox') {
            let div = document.createElement('div');
            for (let s in d.boxReverse) {
                div.style[s] = d.boxReverse[s];
            }
            let l = document.createElement('div');
            for (let s in d.lineStyle) {
                l.style[s] = d.lineStyle[s];
            }

            div.appendChild(l);
            this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML);
        }
        //
        if (d['type'] == 'barCode') {
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            if (!d.isDynamic) {
                this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, d.staticText);
            }
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
            }
        }
        // 转换二维码
        if (d['type'] == 'qr') {
            if (deg == 90) {
                let tmp = width;
                width = height;
                height = tmp;
                top -= (height - width) / 2;
                left += (height - width) / 2;
            }
            this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', d.staticText);
            if (deg == 90) {
                this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
            }
        }

        // 转换图片
        if (d['type'] == 'image') {
            let div = document.createElement('div');
            for (let s in d.boxReverse) {
                div.style[s] = d.boxReverse[s];
            }
            // 旋转
            if (d.boxObject.deg == 90) {
                div.style['writeingMode'] = 'tb-rl';
            }

            let img = document.createElement('img');
            img.setAttribute('src', d.url);
            img.style.width = '100%';
            img.style.height = '100%';
            div.appendChild(img);
            this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML); //增加超文本项
        }
        // 转换表格数据
        if (d['type'] == 'table') {
            //创建表格
            let tableDiv = document.createElement('div');
            for (let s in d.boxReverse) {
                tableDiv.style[s] = d.boxReverse[s];
            }

            let table = document.createElement('table');
            for (let s in d.tableStyle) {
                table.style[s] = d.tableStyle[s];
            }
            tableDiv.appendChild(table);
            //创建行和列
            for (let i = 0; i < d.rows.length; i++) {
                let tr = document.createElement('tr');
                for (let j = 0; j < d.rows[i].length; j++) {
                    let td = document.createElement('td');
                    td.setAttribute('cellspacing', 0);
                    let unit = d.rows[i][j];
                    for (let s in unit.style) {
                        td.style[s] = unit.style[s];
                    }
                    // td.style.fontFamily = '微软雅黑';
                    if (unit.isDynamic == '静态') {
                        td.innerHTML = '<pre style="display:inline;font-family:inherit;">' + unit.valueShow + '</pre>';
                    }

                    tr.appendChild(td);
                }
                table.appendChild(tr);
            }
            let html = tableDiv.outerHTML;
            this.host.LODOP.ADD_PRINT_TABLE(top, left, width, height, html); //增加超文本项
        }
    }

    printStatic(model) {
        //初始化
        let printIndex = 'static';
        this.host.LODOP.PRINT_INIT(printIndex);
        this.host.LODOP.SET_PRINT_PAGESIZE(1, this.host.paper.width * 10, this.host.paper.height * 10, printIndex);
        this.host.LODOP.SET_PRINT_STYLE("FontSize", 11);

        let nzhcn = NZH.cn;
        // 直接打印
        for (var i = 0; i < this.host.dragbox.length; i++) {
            let d = this.host.dragbox[i];
            let top = d.boxObject.top;
            let left = d.boxObject.left;
            let width = d.boxObject.width;
            let height = d.boxObject.height;
            let deg = d.boxObject.deg;
            let textFontSize = d.textObject.fontSizeNum;
            delete d.boxReverse.transform;
            delete d.boxReverse.writeingMode;
            // 直接打印
            if (d.type == 'text') {
                let textObject = d.textObject;
                if (deg == 90) {
                    let tmp = width;
                    width = height;
                    height = tmp + 20;
                    top -= (height - width) / 2;
                    left += (height - width) / 2;
                }
                let className = 'text' + i;
                let allText = d.text + '';
                // 处理大写转换
                if (textObject.textTransform == "uppercase") {
                    allText = nzhcn.toMoney(allText);
                    allText = allText.replace('人民币', '');
                }
                var p = '';
                this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
                // p += "this.host.LODOP.ADD_PRINT_TEXTA('"+className+"',"+top+","+left+","+width+","+height+",'"+d.text+"');";
                // 旋转变换
                if (deg == 90) {
                    this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -3);
                }
                // 处理居中居左
                if (d.boxReverse.textAlign == 'center') {
                    this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                    // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',2);";
                } else if (d.boxReverse.textAlign == 'right') {
                    this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                    // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Alignment',3);";
                }

                this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontName','"+textObject.fontFamily+"');";
                this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
                // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','FontSize',"+textObject.fontSizeNum+");";

                if (textObject.fontWeigiht == 'bold') {
                    this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                    // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Bold',1);";
                }
                if (textObject.fontStyle == 'italic') {
                    this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                    // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Italic',1);";
                }
                if (textObject.textDecoration == 'underline') {
                    this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                    // p += "this.host.LODOP.SET_PRINT_STYLEA('"+className+"','Underline',1);";
                }

                // console.log(p);
            }

            //转换线条
            if (d['type'] == 'lineBox') {
                let div = document.createElement('div');
                for (let s in d.boxReverse) {
                    div.style[s] = d.boxReverse[s];
                }
                let l = document.createElement('div');
                for (let s in d.lineStyle) {
                    l.style[s] = d.lineStyle[s];
                }

                div.appendChild(l);
                this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML);
            }
            //
            if (d['type'] == 'barCode') {
                if (deg == 90) {
                    let tmp = width;
                    width = height;
                    height = tmp;
                    top -= (height - width) / 2;
                    left += (height - width) / 2;
                }
                if (!d.isDynamic) {
                    this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, d.staticText);
                }
                if (deg == 90) {
                    this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                }
            }
            // 转换二维码
            if (d['type'] == 'qr') {
                if (deg == 90) {
                    let tmp = width;
                    width = height;
                    height = tmp;
                    top -= (height - width) / 2;
                    left += (height - width) / 2;
                }
                this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', d.staticText);
                if (deg == 90) {
                    this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                }
            }

            // 转换图片
            if (d['type'] == 'image') {
                let div = document.createElement('div');
                for (let s in d.boxReverse) {
                    div.style[s] = d.boxReverse[s];
                }
                // 旋转
                if (d.boxObject.deg == 90) {
                    div.style['writeingMode'] = 'tb-rl';
                }

                let img = document.createElement('img');
                img.setAttribute('src', d.url);
                img.style.width = '100%';
                img.style.height = '100%';
                div.appendChild(img);
                this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, div.outerHTML); //增加超文本项
            }
            // 转换表格数据
            if (d['type'] == 'table') {
                //创建表格
                let tableDiv = document.createElement('div');
                for (let s in d.boxReverse) {
                    tableDiv.style[s] = d.boxReverse[s];
                }

                let table = document.createElement('table');
                for (let s in d.tableStyle) {
                    table.style[s] = d.tableStyle[s];
                }
                tableDiv.appendChild(table);
                //创建行和列
                for (let i = 0; i < d.rows.length; i++) {
                    let tr = document.createElement('tr');
                    for (let j = 0; j < d.rows[i].length; j++) {
                        let td = document.createElement('td');
                        td.setAttribute('cellspacing', 0);
                        let unit = d.rows[i][j];
                        td.setAttribute('colspan', unit.colspan);
                        td.setAttribute('rowspan', unit.rowspan);

                        for (let s in unit.style) {
                            td.style[s] = unit.style[s];
                        }
                        // td.style.fontFamily = '微软雅黑';
                        if (unit.isDynamic == '静态') {
                            td.innerHTML = '<pre style="display:inline;font-family:inherit;">' + unit.valueShow + '</pre>';
                        }
                        tr.appendChild(td);
                    }
                    table.appendChild(tr);
                }
                let html = tableDiv.outerHTML;
                console.log(html);
                this.host.LODOP.ADD_PRINT_TABLE(top, left, width, height, html); //增加超文本项
            }

        }

        // 发送请求给lodop
        this.host.LODOP.SET_PRINTER_INDEX(this.host.currentPrinter);
        this.host.LODOP.SET_PRINT_COPIES(this.host.printCopies);
        // this.host.LODOP.PREVIEW();
        // this.host.LODOP.PRINT_DESIGN();
        // 2021.06.01新增，打印预览
        if (model > 0) {
            this.host.LODOP.PRINT();
            this.host.printSuccess = true;
        } else {
            this.host.printSuccess = false;
            this.host.LODOP.PREVIEW();
        }
        // 隐藏背景图片
    }
}

export default {
    Printer
}