import apis from "../../common/js/api2";
import tools from '../../../extends/tools.js';
import JSONBIG from 'json-bigint';
import Printer from "./PrintStatic.js";

export default {
    async init() {
        this.printer = new Printer.Printer(this);
        //选择打印机
        this.selectPrinter();
        //判断打印来源
        // 角色一打印操作
        this.selectPrinterFlag1 = true;
        this.tplId = this.printId;
        // 获取打印配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.printConfig = res.data.basic;
            }
        }).catch(err => {
            tools.err(err, this);
        });
    },
    // 格式化JSON数据
    parse(obj) {
        return JSONBIG.parse(obj);
    },
    exit() {
        this.$emit('printLodopExit');
    },
    back() {
        this.$router.push({
            path: '/print'
        })
    },
    selectPrinter() {
        //配置打印机
        let count = this.LODOP.GET_PRINTER_COUNT();
        if (count == 0) {
            this.$alert('无可用打印机', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        let arr = [];
        for (let i = 0; i < count; i++) {
            arr.push(this.LODOP.GET_PRINTER_NAME(i));
        }
        this.printerList = arr;
    },
    printInit(model) {
        // 判角色一
        apis.getPrint({
            id: this.tplId
        }).then((res) => {
            //获取打印数据
            this.dragbox = JSON.parse(res.data.dragbox);
            this.paper = JSON.parse(res.data.paper);
            if (!this.selectFileData) {
                this.selectFileData = [];
            }
            console.log(this.printAction);
            //根据导入的Excel判断动态还是静态
            if (this.printAction === 'simple1') {
                this.printer.printStatic(model);
            } else {
                this.printer.printMutiple(model);
            }
        });
    },
}